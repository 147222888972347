<template>
    <v-container
        fluid
        @drop.prevent="dragover = false"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragend.prevent="dragover = false"
    >
        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
            <PageLayout class="pr-0">
                <template>
                    <v-row>
                        <v-col cols="12">
                            <v-row class="flex-nowrap">
                                <v-col cols="12" class="pt-10">
                                    <h1 v-if="title && title !== 'null'">{{ title }}</h1>
                                 </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-card>
                                <v-card-text>
                                    <v-row class="border_bottom_6 px-3 pb-3" :style="'border-color: #2b95eb !important;'">
                                        <v-col class="pb-0 mb-0" cols="12" sm="5">
                                            <v-list subheader two-line class="background_none py-0 margin_top_6">
                                                <v-list-item class="px-0 py-0 my-0 min_height_auto" >
                                                    <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                        <v-icon color="primary" size="22">
                                                            ${{ 'settingsIcon' }}
                                                        </v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content class="py-0 title_subtitle">
                                                        <v-list-item-subtitle v-text="$t('director')"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                        <v-col class="pb-0 mb-0" cols="12" sm="5" >
                                            <v-list subheader two-line class="background_none py-0 margin_top_6">
                                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                    <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                        <v-icon class="settings_icon_client" color="primary" size="36">
                                                            ${{ 'settingsIcon' }}
                                                        </v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content class="py-0 title_subtitle">
                                                        <v-list-item-subtitle v-text="$t('responsible')"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>

                                        </v-col>

                                    </v-row>
                                    <v-row class="px-3">
                                        <div class="description_title pl-2 pt-1 mt-0">{{ $t('task_description') }}</div>
                                        <v-col cols="12" sm="12" class="ml-0 pl-2">
                                            <div  class="description_text cursor_pointer">
                                                {{ text_deal }}
                                            </div>
                                        </v-col>
                                    </v-row>


                                    <v-row class="py-5">

                                        <v-col cols="12" class="px-0" >
                                            <v-row class="mx-2">
                                                <v-col cols="12">
                                                    <v-btn class="infinity_button" color="primary">
                                                        {{ $t('accept') }}
                                                    </v-btn>
                                                    <v-btn  class="infinity_button ml-2" color="primary">
                                                        {{ $t('complete') }}
                                                    </v-btn>
                                                    <v-btn  text plain class="infinity_button_text">
                                                        {{ $t('fail') }}
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="py-0 mt-1">
                        <v-col cols="12" md="4" class="pr-2">
                            <div class="tab-btn">
                                <div class="d-flex">
                                    <div class="mr-2">
                                        <v-icon size="18" class="add_icon">
                                            mdi-plus-circle-outline
                                        </v-icon>
                                    </div>
                                    <div  class="mr-auto cursor-pointer_header menu_tab">
                                        {{ $t('subtask') }}
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4">
                            <div class="tab-btn">
                                <div class="d-flex">
                                    <div class="mr-2">
                                        <v-icon  size="18" class="add_icon">
                                            mdi-plus-circle-outline
                                        </v-icon>
                                    </div>
                                    <div class="mr-auto cursor-pointer_header menu_tab" >
                                        {{ $t('deal.self') }}
                                    </div>
                                    <div>
                                        <v-icon size="20" class="add_icon mr-2">
                                            mdi-link
                                        </v-icon>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4">
                            <div class="tab-btn">
                                <div class="cursor-pointer_header menu_tab">{{ $t('cases') }}</div>
                            </div>
                        </v-col>
                        <FormTab v-for="tab in tabs" :key="tab.id" :edit="true" :tab="tab" @show-tab="showTab"  @delete-tab="deleteTab"  />

                        <v-col cols="12" md="4">

                               <v-btn

                                    :loading="loading"
                                    block
                                    color="primary"
                                    class="infinity_button mt-1"
                                    @click="addTab"
                                >{{ $t('add') }}
                                </v-btn>

                        </v-col>

                    </v-row>

                    <FormTabField v-for="tab in tabs" :edit="true" entity_type="task" :key="tab.id"  :tab="tab"  />



                    <v-row class="py-0 mt-1">
                        <v-col cols="12">
                            <v-card>
                                <v-card-text class="px-0 mx-0">
                                    <v-row class="px-0 mx-0">
                                        <v-col cols="12" class="ma-0 pa-0" >
                                            <v-card flat>


                                                <v-textarea
                                                    v-model="text"
                                                    :label="$t('message')"
                                                    no-details
                                                    rows="1"
                                                    auto-grow
                                                    full-width
                                                    id="chat-message-typer-textarea"
                                                    :disabled="loading"

                                                    hide-details
                                                    class="input_textarea send_msg"
                                                    ref="textarea"
                                                >
                                                </v-textarea>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>


                </template>

                <template v-slot:side>


                    <v-col cols="12" class="pa-0" style="margin-top: 100px">
                        <v-btn
                            :loading="loading"
                            block
                            color="primary"
                            class="infinity_button"
                            @click="addGroup"
                        >{{ $t('add') }}
                        </v-btn>
                        <FormRight v-for="form in forms" :key="form.sort" entity_type="deal" :edit="true" :form="form" @delete-form="deleteGroup" />
                    </v-col>

                    <v-col cols="12" class="pa-0" style="margin-top: 15px">
                        <v-card class="side-block" >
                            <v-card-title>
                                <div class="participants">{{ $t('additional_information') }}</div>
                            </v-card-title>
                            <v-card-text>
                                <div class="text-info">
                                    <div class="text-info__label">{{ $t('date_created') }}</div>
                                </div>
                                <div class="text-info">
                                    <div class="text-info__label">{{ $t('status') }}</div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" class="pa-0">
                        <v-card class="side-block" style="margin-top: 15px">
                            <div>
                                <div class="d-flex">
                                    <div class="pa-4 participants mr-auto">{{ $t('participants') }}</div>
                                    <div class="pa-4">
                                        <v-icon
                                            size="18"
                                            color="secondary"
                                            class="add_icon"
                                            @click="dialogAddParticipants=true"
                                        >
                                            mdi-plus-circle-outline
                                        </v-icon>
                                    </div>
                                </div>
                            </div>
                            <v-card-text class="mt-0 pt-0">

                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" class="pa-0">
                        <v-card class="side-block" style="margin-top: 15px">
                            <div>
                                <div class="d-flex">
                                    <div class="pa-4 participants mr-auto">{{ $t('files') }}</div>
                                    <div class="pa-4">
                                        <v-icon
                                            size="18"
                                            color="secondary"
                                            class="add_icon"
                                        >
                                            mdi-plus-circle-outline
                                        </v-icon>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <v-card-text class="mt-0 pt-0">

                                </v-card-text>
                            </div>
                        </v-card>
                    </v-col>



                </template>
            </PageLayout>
        </ValidationObserver>

        <ValidationObserver ref="observer_form" v-slot="{ invalid, validated, passes, validate }">
            <v-dialog
                transition="dialog-bottom-transition"
                max-width="700"
                persistent
                v-model="dialogAddGroup"
                class="dialog_body"
            >
                <v-card flat>


                    <v-card-text class="py-0">
                        <v-row>
                            <v-col cols="12">
                                <v-row class="d-flex mt-5">
                                    <v-col cols="10" class="ma-auto">
                                        <span class="title_menu">{{ $t('add_form') }}</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex justify-end">
                                        <v-icon @click="addGroupClose()" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col cols="12" class="py-1" v-for="(language, index) in languages" :key="language.id">
                                <div class="input_label">{{$t('name_form')}} - {{language.name}}</div>
                                <ValidationProvider :ref="language.id" rules="required|min:1|max:255" v-slot="{ errors, valid }">
                                    <v-text-field
                                        :error="!valid"
                                        :error-messages="errors"
                                        :label="$t('name_form') + ' ' + language.name"
                                        autofocus
                                        v-model="language.value"
                                        hide-details
                                        outlined
                                        solo
                                        flat
                                        dense
                                        color="primary"
                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                        clearable
                                    ></v-text-field>
                                </ValidationProvider>

                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-7">
                        <v-btn
                            :disabled="invalid || loading"
                            :loading="loading"
                            :block="$vuetify.breakpoint.xsOnly"
                            color="primary"
                            class="infinity_button"
                            @click="saveGroup"
                        >{{ $t('add') }}
                        </v-btn>
                        <v-btn
                            text
                            @click="addGroupClose()"
                        >{{ $t('close') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </ValidationObserver>


        <ValidationObserver ref="observer_tab" v-slot="{ invalid, validated, passes, validate }">
            <v-dialog
                transition="dialog-bottom-transition"
                max-width="700"
                persistent
                v-model="dialogAddTab"
                class="dialog_body"
            >
                <v-card flat>


                    <v-card-text class="py-0">
                        <v-row>
                            <v-col cols="12">
                                <v-row class="d-flex mt-5">
                                    <v-col cols="10" class="ma-auto">
                                        <span class="title_menu">{{ $t('tab_creation') }}</span>
                                    </v-col>
                                    <v-col cols="2" class="d-flex justify-end">
                                        <v-icon @click="addTabClose()" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col cols="12" class="py-1" v-for="(language, index) in languages" :key="language.id">
                                <div class="input_label">{{$t('tab_name')}} - {{language.name}}</div>
                                <ValidationProvider :ref="language.id" rules="required|min:1|max:255" v-slot="{ errors, valid }">
                                    <v-text-field
                                        :error="!valid"
                                        :error-messages="errors"
                                        :label="$t('tab_name') + ' ' + language.name"
                                        autofocus
                                        v-model="language.value"
                                        hide-details
                                        outlined
                                        solo
                                        flat
                                        dense
                                        color="primary"
                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                        clearable
                                    ></v-text-field>
                                </ValidationProvider>

                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-7">
                        <v-btn
                            :disabled="invalid || loading"
                            :loading="loading"
                            :block="$vuetify.breakpoint.xsOnly"
                            color="primary"
                            class="infinity_button"
                            @click="saveTab"
                        >{{ $t('add') }}
                        </v-btn>
                        <v-btn
                            text
                            @click="addTabClose()"
                        >{{ $t('close') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </ValidationObserver>


    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import debounce from "lodash/debounce";
import PageLayout from "@/components/Leentech/PageLayout.vue"
import FormRight from '@/components/Eav/FormRight.vue';
import FormTab from '@/components/Eav/FormTab.vue';
import FormTabField from '@/components/Eav/FormTabField.vue';

export default {
    name: 'DealTemplateForm',
    components: {
        FormRight,
        FormTab,
        FormTabField,
        ValidationProvider,
        ValidationObserver,
        PageLayout
    },
    inject: ['forceRerender'],
    data() {
        return {

            forms: [
                // {
                //     name: "Форма 1",
                //     group_id: 1,
                //     sort: 1,
                //     fields: {
                //         field_1: {type: "string", label: "Название", rules: "required|min:1|max:255", default: "Сбербанк"},
                //         field_2: {type: "string", label: "Адрес", rules: "min:1|max:255", default: ""},
                //         field_3: {type: "boolean", label: "Логический", rules: "required", default: 1},
                //         field_4: {type: "select", label: "Список", rules: "required|min:1", default: 2, items: [{id: 1, name: 'Test 1'}, {id: 2, name: 'Test 2'}, {id: 3, name: 'Test 3'}]}
                //     }
                // },
                // {
                //     name: "Форма 2",
                //     group_id: 8,
                //     sort: 2,
                //     fields: {
                //         field_8: { type: "string", label: "Название", rules: "required|min:1|max:255", default: "ВТБ" },
                //         field_9: { type: "string", label: "Адрес", rules: "min:1|max:255", default: null},
                //     },
                // },
            ],
            dialogAddGroup: false,


            tabs: [
                // {
                //     name: "Таб 1",
                //     tab_id: 1,
                //     sort: 1,
                //     show: false,
                //     fields: {
                //         field_1: {type: "string", label: "Название", rules: "required|min:1|max:255", default: "Сбербанк"},
                //         field_2: {type: "string", label: "Адрес", rules: "min:1|max:255", default: ""},
                //         field_3: {type: "boolean", label: "Логический", rules: "required", default: 1},
                //         field_4: {type: "select", label: "Список", rules: "required|min:1", default: 2, items: [{id: 1, name: 'Test 1'}, {id: 2, name: 'Test 2'}, {id: 3, name: 'Test 3'}]}
                //     }
                // },
                // {
                //     name: "Таб 2",
                //     tab_id: 5,
                //     sort: 2,
                //     show: false,
                //     fields: {
                //         field_8: { type: "string", label: "Название", rules: "required|min:1|max:255", default: "ВТБ" },
                //         field_9: { type: "string", label: "Адрес", rules: "min:1|max:255", default: null},
                //     },
                // },
            ],
            dialogAddTab: false,
            heading: null,
            author: {},
            progress: 0,
            loading: false,
            tab: 0,
            id: null,
            number: null,
            status_name: null,
            text_deal: "",
            text: null,
            show_title: false,
            title: null,
        }
    },
    computed: {
        ...mapGetters(['language', 'listLanguageNames', 'lang', 'timezone', 'itemsPerPage', 'perPageItems']),
        languages() {
            return this.listLanguageNames
        },
    },
    async mounted() {
        await this.checkCreate()
        await this.getBusinessProcess()
        await this.getTaps()
        await this.getGroups()
    },

    methods: {

        async checkCreate() {
            if (this.$route.name === "deal.create") {
                this.heading = this.$t('deal_creation')
            } else {
                this.heading = this.$t('deal_editing')
            }
        },
        async getBusinessProcess() {
            this.loading = true
            let params = {}
            await this.$http
                .get(`admin/business_process/${this.$route.params.business_process}`, {
                    params: params,
                })
                .then(res => {
                    this.title = res.body.data.name
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_business_process'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async getTaps(){

            this.loading = true;
            let params = {};

            params.type_form = 'tabs';
            params.entity_type = 'deal';
            params.sub_entity_type = 'business_process';
            params.sub_entity_id = this.$route.params.business_process;

            await this.$http
                .get(`admin/dynamic_form`, {
                    params: params,
                })
                .then(res => {
                    if (res?.body?.data?.[0]?.id) {
                        this.tabs = res.body.data;
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_tabs'))
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async getGroups(){

            this.loading = true;
            let params = {};

            params.type_form = 'groups';
            params.entity_type = 'deal';
            params.sub_entity_type = 'business_process';
            params.sub_entity_id = this.$route.params.business_process;

            await this.$http
                .get(`admin/dynamic_form`, {
                    params: params,
                })
                .then(res => {
                    if (res?.body?.data?.[0]?.id) {
                        this.forms = res.body.data;
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_groups'))
                })
                .finally(end => {
                    this.loading = false
                })

        },
        addGroup(){
            this.dialogAddGroup = true;
        },
        addGroupClose(){
            this.dialogAddGroup = false;
            for (let i = 0; i < this.languages.length; i++) {
                this.languages[i].value = null;
            }
        },
        async saveGroup() {


            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            formData.append('entity_type', 'deal')
            formData.append('entity_id', this.$route.params.business_process)
            formData.append('sub_entity_type', 'business_process')
            for (let i = 0; i < this.languages.length; i++) {
                formData.append(`${this.languages[i].id}[name]`, this.languages[i].value)
            }

            await this.$http
                .post('admin/group', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    if (res?.body?.data?.group) {
                        this.forms.push(res.body.data.group);
                        this.addGroupClose();
                        this.$toastr.success(this.$t('group_has_been_added'))
                    }

                })
                .catch(err => {
                    this.$toastr.error(this.$t('group_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        deleteGroup(item){
            const formIndex = this.forms.findIndex(form => form.id === item.id);
            if (formIndex !== -1) {
                this.forms.splice(formIndex, 1);
            }
        },
        showTab(item){
            this.tabs.forEach(tab => {
                if (tab.name === item.name && tab.tab_id === item.tab_id) {
                    tab.show = true;
                } else {
                    tab.show = false;
                }
            });
        },
        addTab(){
            this.dialogAddTab = true;
        },
        addTabClose(){
            this.dialogAddTab = false;
            for (let i = 0; i < this.languages.length; i++) {
                this.languages[i].value = null;
            }
        },
        async saveTab() {

            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            formData.append('entity_type', 'deal')
            formData.append('entity_id', this.$route.params.business_process)
            formData.append('sub_entity_type', 'business_process')
            for (let i = 0; i < this.languages.length; i++) {
                formData.append(`${this.languages[i].id}[name]`, this.languages[i].value)
            }

            await this.$http
                .post('admin/tab', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    if (res?.body?.data?.tab) {
                        this.tabs.push(res.body.data.tab);
                        this.addTabClose()
                        this.$toastr.success(this.$t('tab_has_been_added'))
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('tab_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        deleteTab(item){
            const formIndex = this.tabs.findIndex(tab => tab.id === item.id);
            if (formIndex !== -1) {
                this.tabs.splice(formIndex, 1);
            }
        },




    }
}
</script>


